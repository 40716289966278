import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const Chatrooms = () => {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState({ username: '', gender: '' });

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch available chatrooms when the component mounts
    const fetchRooms = async () => {
      try {
        const response = await fetch(`${apiUrl}/rooms/all`);
        if (!response.ok) {
          throw new Error('Failed to fetch chatrooms');
        }
        const data = await response.json();
        setRooms(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // Fetch user info
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiUrl}/user/current`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }
        const data = await response.json();
        setUserInfo({ username: data.username, gender: data.gender });
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchRooms();
    fetchUserInfo();
  }, [apiUrl]);

  const joinRoom = (roomId) => {
    navigate(`/room/${roomId}?username=${userInfo.username}&gender=${userInfo.gender}`);
  };

  const joinNearbyRoom = () => {
    // Logic to join the default nearby room
    navigate('/room');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white flex flex-col items-center">
      {/* Header with Navigation */}
      <Header />

      {/* Main Content */}
      <main className="w-full max-w-5xl mt-6 px-4">
        <h2 className="text-center text-2xl mb-4 font-semibold">Chatrooms</h2>
        <div className="mb-6 text-center">
          <button
            onClick={joinNearbyRoom}
            className="bg-yellow-500 hover:bg-yellow-400 text-black px-6 py-2 rounded-lg font-semibold transition"
          >
            Join Nearby Room
          </button>
        </div>
        {loading ? (
          <p className="text-center">Loading chatrooms...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : rooms.length === 0 ? (
          <p className="text-center text-gray-400">No chatrooms available.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {rooms.map((room) => (
              <div key={room._id} className="bg-gray-700 p-4 rounded-lg">
                <h3 className="text-xl font-bold text-yellow-300">{room.name}</h3>
                <p className="text-gray-300 mt-1">{room.description}</p>
                {room.location && (
                  <p className="text-gray-400">
                    Location: {room.location.latitude}, {room.location.longitude}
                  </p>
                )}
                <div className="mt-2">
                  <button
                    onClick={() => joinRoom(room._id)}
                    className="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded-lg"
                  >
                    Join Room
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default Chatrooms;
