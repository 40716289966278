import React from "react";
import { useNavigate } from "react-router-dom"; // Importing useNavigate hook

const ContactUs = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white px-6">
      <h1 className="text-3xl md:text-4xl font-bold mb-6 text-center text-purple-400">
        Contact Us
      </h1>
      <p className="text-lg text-center mb-10 text-gray-400">
        Have questions or need assistance? Reach out to us, and we’ll respond as soon as possible!
      </p>

      <div className="flex flex-col md:flex-row gap-12 w-full max-w-4xl">
        {/* Contact Info */}
        <div className="flex flex-col gap-6 text-gray-300">
          <div>
            <h3 className="text-lg font-semibold text-purple-400">Email Us</h3>
            <p className="text-gray-400">unsaidweb.contact@gmail.com</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-purple-400">Call Us</h3>
            <p className="text-gray-400">+1 (672) 380-5008</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-purple-400">Follow Us</h3>
            <div className="flex gap-4 mt-2">
              <a
                href="https://www.reddit.com/r/unsaidChats/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-500 transition"
              >
                Reddit
              </a>
              <a
                href="https://www.instagram.com/getunsaid/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-500 transition"
              >
                Instagram
              </a>
              <a
                href="https://www.linkedin.com/company/un-said/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-500 transition"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <form
          className="flex flex-col gap-4 w-full max-w-md bg-gray-900 p-6 rounded-lg shadow-lg"
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            type="text"
            placeholder="Your Name"
            className="w-full px-4 py-2 text-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-purple-400"
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            className="w-full px-4 py-2 text-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-purple-400"
            required
          />
          <textarea
            placeholder="Your Message"
            className="w-full px-4 py-2 text-gray-900 rounded h-32 focus:outline-none focus:ring-2 focus:ring-purple-400"
            required
          />
          <button
            type="submit"
            className="w-full bg-purple-500 hover:bg-purple-600 text-white py-2 rounded transition"
          >
            Send Message
          </button>
        </form>
      </div>

      {/* Back Button */}
      <button
        onClick={handleBack}
        className="mt-6 text-purple-400 hover:text-purple-500 transition text-lg"
      >
        &larr; Back
      </button>
    </div>
  );
};

export default ContactUs;
