import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const navigate = useNavigate();
  const [customUsername, setCustomUsername] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSetUsername = async () => {
    if (!gender) {
      setError('Please select a gender before proceeding.');
      return;
    }

    if (!password.trim() || password.length < 8) {
      setError(
        'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number.'
      );
      return;
    }

    if (customUsername.trim()) {
      const user = {
        username: customUsername.trim(),
        password,
        gender,
        type: 'authenticated',
        bio : '',
        location : 'Unknown',
        auraPoints : 0
      };

      try {
        setLoading(true); // Set loading state to true
        const response = await fetch(`${apiUrl}/user/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(user),
        });

        if (response.status === 409) {
          setError('This username is already taken. Please choose another.');
        } else if (!response.ok) {
          const errorData = await response.json();
          console.error('Signup error:', errorData.message);
          throw new Error('Failed to create user');
        } else {
          const data = await response.json();
          localStorage.setItem('token', data.token);
          localStorage.setItem('username', data.username);
          navigate('/home');
        }
      } catch (error) {
        setError('Failed to create user. Please try again.');
      } finally {
        setLoading(false); // Reset loading state after the request is complete
      }
    } else {
      setError('Custom username cannot be empty.');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">Sign Up</h1>
      <div className="text-center mt-6 w-full max-w-lg mx-auto">
        <p className="text-lg mb-4">Create a new account:</p>
        <div className="flex flex-col items-center gap-4 w-full">
          <input
            type="text"
            placeholder="Enter your custom username"
            value={customUsername}
            onChange={(e) => setCustomUsername(e.target.value)}
            className="p-3 w-72 md:w-80 lg:w-96 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
          />
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-3 w-72 md:w-80 lg:w-96 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
          />
          
          {/* Password reminder warning */}
          <p className="text-sm text-gray-400 mt-2">
            <strong>Warning:</strong> Please remember or write down your password, as there is no way to recover it.
          </p>

          <div className="mt-6 w-full">
            <p className="text-lg mb-2">Select your gender:</p>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-72 md:w-80 lg:w-96 p-3 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
            >
              <option value="">-- Select Gender --</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <button
            onClick={handleSetUsername}
            className="bg-purple-600 hover:bg-purple-500 text-white px-6 py-3 rounded-full font-semibold shadow-md transition"
            disabled={loading} // Disable button while loading
          >
            {loading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </div>

        {error && (
          <p className="mt-4 text-pink-500">
            {error ===
            'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number.'
              ? 'Password must meet all the requirements: at least 8 characters, include an uppercase letter, a lowercase letter, and a number.'
              : error}
          </p>
        )}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-400">
            By signing up, you agree to our{' '}
            <a href="/terms-of-service" className="text-purple-500 hover:underline">
              Terms of Service
            </a>
            .
          </p>
          <p className="text-sm text-gray-400 mt-4">
            Already have an account?{' '}
            <button
              onClick={() => navigate('/login')}
              className="text-purple-500 hover:underline font-semibold"
            >
              Log In
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
