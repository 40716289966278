import React from 'react';
import MarketplaceItem from './MarketplaceItem';

const MarketplaceGrid = ({ items, onDelete }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {items.map((item) => (
        <MarketplaceItem 
          key={item._id} 
          item={item} 
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

export default MarketplaceGrid;
