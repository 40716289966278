import React from 'react';
import './ChatHeader.css';
import { motion } from 'framer-motion';

const Header = ({ room, userCount, onLeaveRoom }) => {
  return (
    <motion.div
      className="header"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="users-online">
        {userCount === 0
          ? 'No users online'
          : `Online ${userCount} ${userCount === 1 ? 'user' : 'users'}`}
      </div>

      {/* Loading Animation */}
      {!room ? (
        <motion.div
          className="loading-indicator room-info"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
        >
          <span>Loading...</span>
        </motion.div>
      ) : (
        <h1 className="room-info">
          Welcome to <span className="room-name">{room}</span>!
        </h1>
      )}

      <button
        className="leave-room-button rounded-full"
        onClick={onLeaveRoom}
        aria-label="Leave room"
      >
        Leave Room
      </button>
    </motion.div>
  );
};

export default Header;
