import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <div className="max-w-4xl mx-auto">
        {/* Header and Title */}
        <h1 className="text-3xl md:text-4xl font-bold text-purple-400 mb-6 text-center">
          Privacy Policy
        </h1>
        <p className="text-lg mb-4">
          At <span className="text-purple-400 font-semibold">Unsaid</span>, your privacy matters to us. This document explains how we handle the information you share while using our platform, and what measures we take to keep your data safe and secure.
        </p>

        {/* Section 1: Information We Collect */}
        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">1. What We Collect</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">Anonymous Profiles:</span> We offer the option for you to create anonymous profiles without the need to share your name or contact details.
          </li>
          <li>
            <span className="font-semibold">Geolocation Data:</span> With your consent, we collect your approximate location to connect you with nearby rooms and marketplace listings.
          </li>
          <li>
            <span className="font-semibold">Device Information:</span> Non-personal data like your IP address, browser type, and device specifications may be collected to improve platform performance and ensure security.
          </li>
        </ul>

        {/* Section 2: How We Use Your Information */}
        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">2. How We Use Your Data</h2>
        <p className="mb-4">
          The data we collect is used to improve your experience with Unsaid. Specifically, we use it for:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Delivering location-based features like local chat rooms and marketplace listings.</li>
          <li>Enhancing platform performance and troubleshooting technical issues.</li>
          <li>Ensuring safety and preventing misuse, including spam or unauthorized activities.</li>
        </ul>

        {/* Section 3: Data Protection and Sharing */}
        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">3. Data Protection & Sharing</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">We Don’t Sell Your Data:</span> Your data is not for sale. We do not share your information with third-party advertisers.
          </li>
          <li>
            <span className="font-semibold">Encryption:</span> We use encryption to protect sensitive information such as messages and location data.
          </li>
          <li>
            <span className="font-semibold">Third-Party Services:</span> If we use third-party services (such as Google Maps API), they comply with applicable data protection laws.
          </li>
        </ul>

        {/* Section 4: Your Control Over Data */}
        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">4. Control Over Your Data</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>Manage or delete your profile and data via the app’s settings.</li>
          <li>Revoke location access directly from your device settings.</li>
          <li>Contact us anytime to request data modifications or deletion.</li>
        </ul>

        {/* Section 5: Updates to Privacy Policy */}
        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">5. Policy Updates</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. Any changes will be reflected here, and the updated version will always be accessible on this page.
        </p>

        {/* Section 6: Contact Information */}
        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">6. Contact Us</h2>
        <p>
          If you have any questions or need further clarification regarding this Privacy Policy, please don't hesitate to reach out to us at: 
          <span className="text-purple-400"> unsaidweb.contact@gmail.com</span>.
        </p>

        {/* Go Back Button */}
        <div className="mt-8 text-center">
          <button
            onClick={goBack}
            className="bg-purple-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-500 transition"
          >
            Go Back
          </button>
        </div>

        {/* Last Updated Date */}
        <p className="text-sm text-gray-400 mt-8 text-center">
          Last updated: {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
