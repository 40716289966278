import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import CreateListingModal from '../../components/marketplace/CreateListingModal';
import MarketplaceGrid from '../../components/marketplace/MarketplaceGrid';

const Marketplace = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    images: [],
    category: '',
    condition: '',
  });
  const [imagePreview, setImagePreview] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [sortOption, setSortOption] = useState('date');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterCondition, setFilterCondition] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMarketplaceItems = async () => {
      try {
        const response = await fetch(`${apiUrl}/marketplace/all`);
        if (!response.ok) throw new Error('Failed to fetch marketplace items');
        const data = await response.json();
        setItems(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMarketplaceItems();
  }, [apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (!files) return;

    const allowedExtensions = /\.(jpeg|jpg|png|gif|webp)$/i;
    const uploadedImages = Array.from(files).filter((file) => {
      if (!allowedExtensions.test(file.name)) {
        alert(`${file.name} is not a supported file type.`);
        return false;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert(`${file.name} exceeds the 5MB size limit.`);
        return false;
      }
      return true;
    });

    const previewUrls = uploadedImages.map((file) => URL.createObjectURL(file));

    setFormData((prev) => ({ ...prev, images: uploadedImages }));
    setImagePreview(previewUrls);
  };

  const isValidForm = () => {
    const { title, description, price, images, category, condition } = formData;
    return title.trim() && description.trim() && price && images.length > 0 && category && condition;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidForm()) {
      alert('Please fill out all fields and upload at least one image.');
      return;
    }

    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      const uploadData = new FormData();
      formData.images.forEach((file) => uploadData.append('images', file));
      uploadData.append('title', formData.title);
      uploadData.append('description', formData.description);
      uploadData.append('price', formData.price);
      uploadData.append('category', formData.category);
      uploadData.append('condition', formData.condition);

      const response = await fetch(`${apiUrl}/marketplace/create`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: uploadData,
      });

      if (!response.ok) throw new Error('Failed to create marketplace item');

      const newItem = await response.json();
      setItems([newItem, ...items]);
      setFormData({
        title: '',
        description: '',
        price: '',
        images: [],
        category: '',
        condition: '',
      });
      setImagePreview([]);
      setIsModalOpen(false);
    } catch (err) {
      console.error('Error creating marketplace item:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = (itemId) => {
    setItems((prevItems) => prevItems.filter((item) => item._id !== itemId));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Sorting function
  const sortItems = (items) => {
    switch (sortOption) {
      case 'price':
        return items.sort((a, b) => a.price - b.price);
      case 'date':
      default:
        return items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
  };

  // Filtered and sorted items with search query
  const filteredItems = items
    .filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((item) => (filterCategory ? item.category === filterCategory : true))
    .filter((item) => (filterCondition ? item.condition === filterCondition : true));

  const sortedItems = sortItems(filteredItems);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white flex flex-col items-center">
      <Header />

      <main className="w-full max-w-5xl mt-6 px-4">
        <h2 className="text-center text-2xl mb-4 font-semibold">Marketplace</h2>

        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search for items..."
          className="bg-gray-800 text-white py-2 px-4 rounded-lg mb-6 w-full"
        />

        <button
          onClick={openModal}
          className="bg-yellow-500 text-black py-2 px-4 rounded-lg mb-6 hover:bg-yellow-600 transition"
        >
          Create Listing
        </button>

        <CreateListingModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleSubmit}
          formData={formData}
          handleChange={handleChange}
          handleImageUpload={handleImageUpload}
          imagePreview={imagePreview}
          isSubmitting={isSubmitting}
        />

        <div className="flex justify-between items-center mb-4">
          <select
            onChange={(e) => setSortOption(e.target.value)}
            className="bg-gray-800 text-white py-2 px-4 rounded-lg"
          >
            <option value="date">Sort by Date</option>
            <option value="price">Sort by Price</option>
          </select>

          <div>
            <select
              onChange={(e) => setFilterCategory(e.target.value)}
              className="bg-gray-800 text-white py-2 px-4 rounded-lg"
            >
              <option value="">Filter by Category</option>
              <option value="electronics">Electronics</option>
              <option value="furniture">Furniture</option>
              <option value="clothing">Clothing</option>
            </select>

            <select
              onChange={(e) => setFilterCondition(e.target.value)}
              className="bg-gray-800 text-white py-2 px-4 rounded-lg ml-4"
            >
              <option value="">Filter by Condition</option>
              <option value="new">New</option>
              <option value="used">Used</option>
            </select>
          </div>
        </div>

        {loading ? (
          <p className="text-center">Loading items...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <MarketplaceGrid items={currentItems} onDelete={handleDelete} />
        )}

        <div className="flex justify-center mt-6">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-700 text-white py-2 px-4 rounded-lg mr-2 disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastItem >= sortedItems.length}
            className="bg-gray-700 text-white py-2 px-4 rounded-lg ml-2 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </main>
    </div>
  );
};

export default Marketplace;
