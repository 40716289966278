import React, { useState } from 'react';
import DetailedListingModal from './DetailedListingModal';

const MarketplaceItem = ({ item, onDelete, onEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Ensure item has all necessary properties before rendering
  if (!item) return <div>Loading...</div>;

  // Check if the current user is the owner of the item
  const isOwner = item.userId?._id === localStorage.getItem('userId');

  // Delete function
  const deleteItem = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/marketplace/${item._id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete item');
      }

      // Call onDelete callback to remove the item from the marketplace list
      onDelete(item._id);
    } catch (err) {
      console.error('Error deleting item:', err);
    }
  };

  // Prevent modal from opening when clicking on Edit or Delete
  const handleButtonClick = (e) => {
    e.stopPropagation(); // Prevents the div's onClick from firing
  };

  return (
    <div
      className="bg-gray-700 p-4 rounded-lg cursor-pointer"
      onClick={openModal}
      role="button"
      aria-label={`View details of ${item.title}`}
    >
      {/* Display the first image for preview */}
      <img
        src={item.images?.[0] || '/fallback-image.png'}  // Fallback image in case of missing images
        alt={item.title || 'Marketplace item'}
        className="w-full h-40 object-cover rounded-lg"
        loading="lazy"
      />
      <h3 className="text-xl font-bold text-yellow-300 mt-2">{item.title}</h3>
      <p className="text-gray-400 mt-1">Price: ${item.price}</p>
      <p className="text-gray-500 mt-1">Seller: {item.userId?.username || 'Unknown'}</p>

      {/* Conditionally render Edit and Delete buttons for item owner */}
      {isOwner ? (
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handleButtonClick} // Prevent opening modal
            className="bg-blue-500 hover:bg-blue-400 text-white px-3 py-1 rounded-lg"
          >
            Edit
          </button>
          <button
            onClick={(e) => {
              handleButtonClick(e); // Prevent opening modal
              deleteItem();
            }}
            className="bg-red-500 hover:bg-red-400 text-white px-3 py-1 rounded-lg"
          >
            Delete
          </button>
        </div>
      ) : (
        // Show "Contact Seller" button if not the owner
        <div className="mt-4">
          <button className="bg-green-500 hover:bg-green-400 text-white px-6 py-2 rounded-lg">
            Contact Seller
          </button>
        </div>
      )}

      <DetailedListingModal item={item} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default MarketplaceItem;
