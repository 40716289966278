import React, { useCallback, useState, useEffect } from 'react';

const Post = ({ post }) => {
  const [likes, setLikes] = useState(post.likes || 0);
  const [comments, setComments] = useState(post.comments || []);
  const [commentContent, setCommentContent] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Handle liking, commenting, and sharing the post
  const handleReaction = async (reaction) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/posts/${reaction}/${post._id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to update ${reaction}`);
      }

      const updatedPost = await response.json();
      if (reaction === 'like') {
        setLikes(updatedPost.likes);
      } else if (reaction === 'comment') {
        setComments(updatedPost.comments);
      }
    } catch (error) {
      console.error(`Error updating ${reaction}:`, error);
    }
  };

  // Fetch comments for the post
  const fetchComments = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/posts/comments/${post._id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch comments');
      }
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [apiUrl, post._id]); // Dependencies are apiUrl and post._id

  useEffect(() => {
    fetchComments(); // Fetch comments when the component mounts
  }, [fetchComments]); // Only run when fetchComments changes


  useEffect(() => {
    fetchComments(); // Fetch comments when the component mounts
  }, [post._id, fetchComments]);

  // Handle new comment submission
  const handleComment = async () => {
    if (!commentContent.trim()) {
      setError('Comment cannot be empty');
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/posts/comment/${post._id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: commentContent }),
      });

      if (!response.ok) {
        throw new Error('Failed to add comment');
      }

      const newComment = await response.json();
      setComments((prevComments) => [...prevComments, newComment]);
      setCommentContent('');
      setError('');
    } catch (error) {
      console.error('Error adding comment:', error);
      setError('Failed to add comment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="bg-gray-800 p-4 rounded-lg mb-4 shadow-md w-full">
  <div className="flex items-start">
    <h3 className="text-xs font-semibold text-yellow-300 mr-2">{post.username}</h3>
    {post.location && (
      <p className="text-xs text-gray-400">
        ({post.location.latitude}, {post.location.longitude})
      </p>
    )}
  </div>
  <p className="text-lg text-gray-300 font-extrabold mt-2">{post.content}</p>

  {/* Reactions section */}
  <div className="flex mt-4 items-center">
    <button
      onClick={() => handleReaction('like')}
      className="mr-4 text-lg text-gray-400 hover:text-gray-100"
    >
      👍 {likes}
    </button>
    <button
      onClick={() => handleReaction('comment')}
      className="mr-4 text-lg text-gray-400 hover:text-gray-100"
    >
      💬 {comments.length}
    </button>
  </div>

  {/* Comment Section */}
  <div className="mt-4">
    <input
      type="text"
      placeholder="Add a comment..."
      value={commentContent}
      onChange={(e) => setCommentContent(e.target.value)}
      className="w-full p-2 bg-transparent border-b-2 border-gray-500 text-white focus:outline-none focus:border-purple-600 text-sm"
    />
    <button
      onClick={handleComment}
      className="mt-2 text-sm bg-purple-600 hover:bg-purple-500 text-white px-4 py-2 rounded-full font-semibold shadow-md transition"
      disabled={loading}
    >
      {loading ? 'Posting...' : 'Post'}
    </button>
    {error && <p className="mt-2 text-pink-500 text-sm">{error}</p>}
  </div>

  {/* Display Comments */}
  <div className="mt-4">
    {comments.length === 0 ? (
      <p className="text-gray-500 text-sm">No comments yet.</p>
    ) : (
      comments.map((comment) => (
        <div key={comment._id} className="py-2">
          <p className="font-semibold text-yellow-300 text-xs">{comment.username}</p>
          <p className="text-gray-300 text-sm">{comment.content}</p>
        </div>
      ))
    )}
  </div>
</div>

  );
};

export default Post;
