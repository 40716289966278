import React, { useState } from 'react';

// Custom Modal Component for Detailed Listing
const DetailedListingModal = ({ item, isOpen, onClose }) => {
  // Fallback image in case of missing images
  const fallbackImage = '/fallback-image.png';

  // Ensure that the useState is called before any conditional rendering
  const [selectedImage, setSelectedImage] = useState(item?.images?.[0] || fallbackImage);

  // Function to handle image selection
  const handleImageClick = (image) => {
    setSelectedImage(image || fallbackImage);
  };

  // Early return if the item doesn't exist
  if (!item) return null;

  const handleCloseModal = (e) => {
    e.stopPropagation(); // Prevent click from propagating to the parent
    onClose(); // Close the modal
  };

  return (
    isOpen && (
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300"
        onClick={onClose} // Close modal when clicking the background
      >
        <div
          className="bg-gradient-to-b from-gray-800 via-gray-900 to-black rounded-lg shadow-lg w-full max-w-3xl p-6 max-h-[90vh] overflow-y-auto transition-transform duration-300 transform scale-95 hover:scale-100"
          onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal when clicking inside the modal
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-white">Listing Details</h2>
            <button
              onClick={handleCloseModal}
              className="text-gray-400 hover:text-purple-500 transition duration-200"
              aria-label="Close Modal"
            >
              ✕
            </button>
          </div>
          <div className="space-y-6">
            <h3 className="text-3xl font-bold text-yellow-300">{item.title || 'No Title'}</h3>
            <p className="text-lg text-gray-300">{item.description || 'No description available.'}</p>
            <p className="text-lg text-gray-400">Price: ${item.price || 'N/A'}</p>
            <p className="text-lg text-gray-500">Category: {item.category || 'No category available.'}</p>
            <p className="text-lg text-gray-500">Condition: {item.condition || 'No condition specified.'}</p>

            <div className="mt-6">
              {/* Main Image Display */}
              <div className="mb-6">
                <img
                  src={selectedImage}
                  alt="Selected Item"
                  className="w-full h-80 object-cover rounded-lg shadow-lg transform transition duration-500 ease-in-out hover:scale-105"
                  loading="lazy"
                />
              </div>

              {/* Thumbnails for Images */}
              {item.images && item.images.length > 0 ? (
                <div className="flex space-x-4 overflow-x-auto py-2">
                  {item.images.map((image, index) => (
                    <img
                      key={index}
                      src={image || fallbackImage}
                      alt={`thumbnail-${index}`}
                      className="w-24 h-24 object-cover rounded-lg cursor-pointer border-2 border-transparent hover:border-purple-500 transition-all duration-300"
                      onClick={() => handleImageClick(image)}
                      loading="lazy"
                    />
                  ))}
                </div>
              ) : (
                <div className="text-center text-gray-500">No images available</div>
              )}
            </div>

            <div className="flex justify-center mt-6">
              <button
                onClick={handleCloseModal}
                className="w-full max-w-xs bg-purple-500 text-white py-3 rounded-lg hover:bg-purple-600 transition font-semibold shadow-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DetailedListingModal;
