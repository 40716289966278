import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;


  const handleLogin = async () => {
    setPassword(password.trim());

    if (!username.trim() || !password.trim()) {
      setError('Both username and password are required.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'Failed to login. Please try again.');
        return;
      }

      // Store token and user data in localStorage
      localStorage.setItem('token', data.token);  // Use the correct token field
      localStorage.setItem('username', data.username);
      localStorage.setItem('userId', data.id);

      navigate('/home');
    } catch (error) {
      console.error('Login error:', error);
      setError('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">
        Log In
      </h1>
      <div className="text-center mt-6 w-full">
        <p className="text-lg mb-4">Log in with your username and password:</p>
        <div className="flex flex-col items-center gap-4 w-full max-w-lg mx-auto">
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            aria-label="Username"
            className="p-3 w-72 md:w-80 lg:w-96 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
          />
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Password"
            className="p-3 w-72 md:w-80 lg:w-96 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
          />
          <button
            onClick={handleLogin}
            disabled={loading}
            className={`bg-purple-600 hover:bg-purple-500 text-white px-6 py-3 rounded-full font-semibold shadow-md transition ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Logging In...' : 'Log In'}
          </button>
        </div>
        {error && <p className="mt-4 text-pink-500">{error}</p>}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-400">
            By continuing, you agree to our{' '}
            <a href="/terms-of-service" className="text-purple-500 hover:underline">
              Terms of Service
            </a>
            .
          </p>
          <p className="text-sm text-gray-400 mt-4">
            Don't have an account?{' '}
            <button
              onClick={() => navigate('/signup')}
              className="text-purple-500 hover:underline font-semibold"
            >
              Sign Up
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
