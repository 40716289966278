import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Landing.css';

const Landing = () => {
  const navigate = useNavigate();

  // Framer Motion Animation
  const fadeIn = { initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 1 } };

  return (
    <motion.div {...fadeIn} className="bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white">
      {/* Hero Section */}
      <motion.section
        {...fadeIn}
        className="min-h-screen flex flex-col items-center justify-center px-6 text-center"
      >
        <h1 className="text-5xl md:text-6xl font-extrabold mb-6 leading-tight">
          Welcome to <span className="text-purple-500">Unsaid</span>
        </h1>
        <p className="text-lg md:text-xl max-w-2xl mx-auto mb-8">
          A fresh way to connect, chat, and share—all within your local community. No data overload, just real interactions.
        </p>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <button
            onClick={() => navigate('/login')}
            className="bg-purple-500 hover:bg-blue-500 text-white px-6 py-3 rounded-full font-semibold shadow-md transition"
          >
            Join Now
          </button>
          <button
            onClick={() => {
              const targetSection = document.querySelector('.features-section');
              if (targetSection) targetSection.scrollIntoView({ behavior: 'smooth' });
            }}
            className="bg-transparent border-2 border-purple-500 hover:border-blue-500 text-white px-6 py-3 rounded-full font-semibold transition"
          >
            See How It Works
          </button>
        </div>
      </motion.section>

      {/* Features Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-gray-900 features-section">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12">
          Why Choose <span className="text-purple-500">Unsaid</span>?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {[
            { title: 'Meet Locally', desc: 'Connect with people in your area—no matter where you are.' },
            { title: 'Privacy First', desc: 'Stay anonymous. Control your data, your way.' },
            { title: 'Private Rooms', desc: 'Create rooms for exclusive chats—your own space, your own rules.' },
            { title: 'Marketplace', desc: 'Buy and sell items with ease—only within your local community.' },
          ].map((feature, idx) => (
            <div
              key={idx}
              className="bg-gray-800 p-6 rounded-lg text-center hover:scale-105 transition transform"
            >
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.desc}</p>
            </div>
          ))}
        </div>
      </motion.section>

      {/* Testimonials Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800">
        <h3 className="text-3xl md:text-5xl font-bold text-center mb-12">What Our Users Say</h3>
        <div className="flex flex-wrap justify-center gap-8 max-w-5xl mx-auto">
          {[
            { text: 'I’ve made so many new friends nearby. It feels so personal and real!', name: 'Alex M.' },
            { text: 'I love that I can chat without worrying about my privacy. Finally, a platform I can trust.', name: 'Jamie S.' },
            { text: 'Unsaid made it easy for me to buy and sell items in my area. So convenient!', name: 'Taylor L.' },
          ].map((testimonial, idx) => (
            <div key={idx} className="bg-gray-800 p-6 rounded-lg shadow-md w-80 text-center">
              <p className="italic text-gray-300">"{testimonial.text}"</p>
              <h4 className="mt-4 font-semibold text-purple-500">{testimonial.name}</h4>
            </div>
          ))}
        </div>
      </motion.section>

      {/* How It Works Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-gray-900">
        <h3 className="text-3xl md:text-5xl font-bold text-center mb-12">How It Works</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            { step: '1', desc: 'Sign up quickly and create your anonymous profile.' },
            { step: '2', desc: 'Browse local users and make connections based on your area.' },
            { step: '3', desc: 'Share posts, chat, and shop—securely and locally.' },
          ].map((item, idx) => (
            <div
              key={idx}
              className="bg-gray-800 p-6 rounded-lg text-center hover:scale-105 transition transform"
            >
              <h4 className="text-4xl font-bold mb-4 text-purple-500">{item.step}</h4>
              <p className="text-gray-400">{item.desc}</p>
            </div>
          ))}
        </div>
      </motion.section>

      {/* Call-to-Action Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-purple-500 text-black text-center">
        <h2 className="text-3xl md:text-5xl font-bold mb-6">Ready to Connect Locally?</h2>
        <p className="text-lg md:text-xl mb-8">
          Join <span className="font-bold">Unsaid</span> today and start connecting with your neighbors, securely and easily.
        </p>
        <button
          onClick={() => navigate('/signup')}
          className="bg-black text-purple-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-900 transition"
        >
          Sign Up Now
        </button>
      </motion.section>

      {/* Footer Section */}
      <footer className="bg-gray-900 py-6 text-center text-white shadow-lg">
        <div className="container mx-auto">
          <p className="text-sm mb-4">© 2024 Unsaid. All rights reserved.</p>
          <div className="flex justify-center space-x-4 mb-4 space-y-4 md:space-y-0 flex-col md:flex-row">
            <Link to="/privacy-policy" className="text-gray-400 hover:text-purple-500 transition">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="text-gray-400 hover:text-purple-500 transition">
              Terms of Service
            </Link>
            <Link to="/contact-us" className="text-gray-400 hover:text-purple-500 transition">
              Contact Us
            </Link>
          </div>
          <p className="text-sm">
            Built by{' '}
            <a
              href="https://www.linkedin.com/in/iamjeel"
              className="text-purple-500 hover:text-purple-600 transition"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jeel Thumar
            </a>
          </p>
        </div>
      </footer>
    </motion.div>
  );
};

export default Landing;
