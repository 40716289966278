import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-purple-400 mb-6 text-center">
          Terms of Service
        </h1>
        <p className="text-lg mb-4">
          Welcome to <span className="text-purple-400 font-semibold">Unsaid</span>. These Terms of Service govern your use of our platform. Please read them carefully before accessing or using any of our services.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing or using <span className="text-purple-400">Unsaid</span>, you agree to comply with these Terms of Service, our Privacy Policy, and all applicable laws. If you do not agree, you must immediately stop using our platform.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">2. Eligibility and Use of the Service</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">Eligibility:</span> You must be at least 16 years old to use Unsaid. If you are under 16, you must obtain consent from a parent or guardian.
          </li>
          <li>
            <span className="font-semibold">Responsible Use:</span> You agree to use Unsaid responsibly, respecting the anonymity and privacy of others, and engaging only in lawful activities.
          </li>
          <li>
            <span className="font-semibold">Location-Based Services:</span> By using Unsaid, you consent to the use of location services to facilitate connections and content relevant to your area.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">3. Prohibited Activities</h2>
        <p className="mb-4">
          You are prohibited from engaging in the following activities:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Violating any laws or regulations.</li>
          <li>Posting harmful, abusive, offensive, or illegal content.</li>
          <li>Misusing the platform to harass, impersonate, or deceive other users.</li>
          <li>Attempting to disrupt, damage, or interfere with the platform's functionality.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">4. Content Ownership and Rights</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">Your Content:</span> You retain full ownership of any content you share, but by using Unsaid, you grant us the right to use your content for providing the service (including displaying it on the platform).
          </li>
          <li>
            <span className="font-semibold">Moderation:</span> We reserve the right to remove any content that violates these Terms of Service, is deemed inappropriate, or harms the platform’s integrity.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">5. Limitation of Liability</h2>
        <p className="mb-4">
          Unsaid is provided on an "as is" basis, and we do not offer any guarantees or warranties regarding the availability or functionality of the platform. We are not liable for any damages, losses, or issues that may arise from using the service.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">6. Termination of Access</h2>
        <p className="mb-4">
          We reserve the right to suspend or terminate your access to Unsaid if you violate these Terms of Service or engage in activities that threaten the safety, security, or integrity of the platform.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">7. Updates and Changes to Terms</h2>
        <p className="mb-4">
          These Terms of Service may be updated periodically. Any significant changes will be communicated to users, and the updated terms will be posted on this page with a revised date.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">8. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms of Service, please reach out to us at: 
          <span className="text-purple-400"> unsaidweb.contact@gmail.com</span>.
        </p>

        <p className="text-sm text-gray-400 mt-8">
          Last updated: {new Date().toLocaleDateString()}
        </p>

        <div className="mt-8 text-center">
          <Link
            to="/"
            className="text-purple-400 hover:text-purple-500 text-lg font-semibold"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
