import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Post from './Post';
import Header from '../Header';

const Home = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('Guest');
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const [location, setLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  // Get current user's location
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');

    if (!token) {
      navigate('/login'); // Redirect to login if not authenticated
    } else {
      setUsername(storedUsername || 'Guest');
    }

    // Get user location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocation(null);
        }
      );
    }

    const fetchPosts = async () => {
      try {
        const response = await fetch(`${apiUrl}/posts/all`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch posts');
        }

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setErrorMessage('Failed to load posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [navigate, apiUrl]);

  const handlePostSubmit = async (e) => {
    e.preventDefault();

    if (content.trim() === '') {
      setErrorMessage('Content is required');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/posts/create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content, location }),
      });

      if (!response.ok) {
        throw new Error('Failed to create post');
      }

      const newPost = await response.json();
      setPosts([newPost, ...posts]);
      setContent('');
      setLocation(null); // Reset location after posting
      setErrorMessage(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error creating post:', error);
      setErrorMessage('Failed to create post. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white flex flex-col items-center">
      <Header />

      <main className="w-full max-w-3xl mt-6 px-4 space-y-4">
        <h2 className="text-center text-2xl mb-4 font-semibold">Welcome, {username}</h2>

        {/* Post Form */}
        <form onSubmit={handlePostSubmit} className="mb-6">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="What's on your mind?"
            className="w-full p-2 mb-2 rounded bg-gray-700 text-white border border-gray-600 focus:outline-none"
          ></textarea>
          <button type="submit" className="bg-yellow-400 text-black p-2 rounded hover:bg-yellow-500">
            Post
          </button>
        </form>

        {errorMessage && (
          <p className="text-red-500 text-center">{errorMessage}</p>
        )}

        {loading ? (
          <p className="text-center text-gray-400">Loading posts...</p>
        ) : (
          posts.map((post) => (
            <Post
              key={post._id}
              post={post}
            />
          ))
        )}
      </main>
    </div>
  );
};

export default Home;
