import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { FaSignOutAlt, FaTrashAlt } from 'react-icons/fa';

const EditProfile = () => {
    const navigate = useNavigate();
    const [gender, setGender] = useState('');
    const [username, setUsername] = useState('');
    const [location, setLocation] = useState('');
    const [bio, setBio] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/current`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setGender(data.gender || '');
                setUsername(data.username || '');
                setLocation(data.location || '');  // Pre-fill location
                setBio(data.bio || '');            // Pre-fill bio
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to load user data');
            }
        };

        fetchUserData();
    }, [apiUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!gender) {
            setError('Please select a gender.');
            return;
        }

        if (!username) {
            setError('Please enter a username.');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${apiUrl}/user/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ gender, username, location, bio }), // Include location and bio here
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update profile');
            }

            navigate('/profile');
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate(-1); 
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');  // Redirect to landing page after logout
    };

    const handleDeleteProfile = async () => {
        try {
            const response = await fetch(`${apiUrl}/user/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete profile');
            }

            // Logout user after successful deletion
            localStorage.removeItem('token');
            navigate('/landing');
        } catch (error) {
            console.error('Error deleting profile:', error);
            setDeleteError('Failed to delete profile. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white">
            <Header />

            <div className="flex flex-col items-center pt-12 px-4">
                <h1 className="text-4xl font-bold mb-6 text-center">Edit Profile</h1>

                <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-2xl">
                    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                        {/* Form inputs for username, gender, location, bio */}
                        <div className="flex flex-col items-center gap-2">
                            <input
                                type="text"
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="p-3 w-full bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
                            />
                        </div>

                        <div className="mt-4">
                            <p className="text-lg mb-2">Select your gender:</p>
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="w-full p-3 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
                            >
                                <option value="">-- Select Gender --</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Non-Binary">Non-Binary</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div className="mt-4">
                            <p className="text-lg mb-2">Location:</p>
                            <input
                                type="text"
                                placeholder="Enter your location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="p-3 w-full bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
                            />
                        </div>

                        <div className="mt-4">
                            <p className="text-lg mb-2">Bio:</p>
                            <textarea
                                placeholder="Write a short bio"
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                                className="p-3 w-full bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
                            />
                        </div>

                        <div className="flex gap-4 mt-6">
                            <button
                                type="submit"
                                className="bg-purple-600 hover:bg-purple-500 text-white px-6 py-3 rounded-md"
                                disabled={loading}
                            >
                                {loading ? 'Updating...' : 'Save Changes'}
                            </button>

                            <button
                                type="button"
                                onClick={handleCancel}
                                className="bg-gray-600 hover:bg-gray-500 text-white px-6 py-3 rounded-md"
                            >
                                Cancel
                            </button>
                        </div>

                        {error && (
                            <div className="bg-red-600 text-white text-center p-4 rounded-md mt-4">
                                {error}
                            </div>
                        )}
                    </form>

                    <div className="mt-8 text-center">
                        <button
                            onClick={handleLogout}
                            className="bg-yellow-600 hover:bg-yellow-500 text-white px-6 py-3 rounded-md mb-4"
                        >
                            <FaSignOutAlt className="inline mr-2" />
                            Logout
                        </button>
                    </div>

                    <div className="mt-8 text-center">
                        <button
                            onClick={() => setShowDeleteModal(true)}
                            className="bg-red-600 hover:bg-red-500 text-white px-6 py-3 rounded-md"
                        >
                            <FaTrashAlt className="inline mr-2" />
                            Delete Profile
                        </button>
                    </div>
                </div>

                {/* Delete Confirmation Modal */}
                {showDeleteModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-800 text-white p-8 rounded-lg max-w-sm w-full">
                            <h2 className="text-xl font-semibold mb-4">Are you sure you want to delete your profile?</h2>
                            {deleteError && (
                                <div className="bg-red-600 text-white text-center p-4 rounded-md mb-6">
                                    {deleteError}
                                </div>
                            )}
                            <div className="flex gap-4">
                                <button
                                    onClick={handleDeleteProfile}
                                    className="bg-red-600 hover:bg-red-500 text-white px-6 py-3 rounded-md"
                                >
                                    Yes, Delete
                                </button>
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    className="bg-gray-600 hover:bg-gray-500 text-white px-6 py-3 rounded-md"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditProfile;
