import { io } from 'socket.io-client';

let socket;

/**
 * Connects to the Socket.IO server with the given latitude and longitude.
 * @param {number} latitude - The latitude coordinate of the user.
 * @param {number} longitude - The longitude coordinate of the user.
 * @returns {object} The socket instance.
 */
export const connectSocket = (latitude, longitude) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  if (!socket || !socket.connected) {
    socket = io(apiUrl, {
      query: { latitude, longitude },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    // Add event listeners for better error handling
    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    socket.on('connect_error', (error) => {
      console.error('Connection failed:', error.message);
    });

    socket.on('disconnect', (reason) => {
      console.warn(`Disconnected: ${reason}`);
      if (reason === 'io server disconnect') {
        // The server disconnected the client, manually reconnect
        socket.connect();
      }
    });
  }

  return socket;
};

/**
 * Returns the current socket instance.
 * @returns {object|null} The socket instance or null if not connected.
 */
export const getSocket = () => socket;

/**
 * Disconnects from the Socket.IO server and resets the socket instance.
 */
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    console.log('Disconnected from the server');
  }
};
