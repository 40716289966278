import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { FaEdit } from 'react-icons/fa';

const Profile = () => {
    const navigate = useNavigate();
    const [gender, setGender] = useState('');
    const [username, setUsername] = useState('');
    const [location, setLocation] = useState('');
    const [auraPoints, setAuraPoints] = useState(0);
    const [bio, setBio] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/current`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setGender(data.gender || '');
                setUsername(data.username || '');
                setLocation(data.location || 'Unknown Location');
                setAuraPoints(data.auraPoints || 0);
                setBio(data.bio || 'No bio available');
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to load user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [apiUrl]);

    const handleEditProfile = () => {
        navigate('/edit-profile');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white flex flex-col items-center">
            <Header />

            <main className="w-full max-w-4xl mt-8 px-4 flex flex-col items-center">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-purple-600"></div>
                        <p className="ml-4 text-lg">Loading profile...</p>
                    </div>
                ) : (
                    <>
                        {error && (
                            <div className="bg-red-600 text-white text-center p-4 rounded-md mb-6 w-full max-w-md">
                                {error}
                            </div>
                        )}
                        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-xl flex flex-col items-center relative">
                            {/* Edit Icon in the top right corner */}
                            <button
                                onClick={handleEditProfile}
                                className="absolute top-4 right-4 text-purple-600 hover:text-purple-500"
                            >
                                <FaEdit className="text-2xl" />
                            </button>

                            <div className="w-36 h-36 rounded-full bg-gray-600 mb-6 flex items-center justify-center">
                                <p className="text-5xl font-bold text-white uppercase">{username[0]}</p>
                            </div>

                            <h2 className="text-3xl font-semibold mb-2 text-center flex items-center justify-center gap-2">
                                {username}
                                <span
                                    className={`w-6 h-6 flex items-center justify-center rounded-full text-white text-sm font-semibold ${gender === 'Male' ? 'bg-blue-600' : 'bg-pink-600'}`}
                                >
                                    {gender === 'Male' ? 'M' : 'F'}
                                </span>
                            </h2>

                            <div className="text-lg text-gray-300 mb-2 text-center">{location}</div>

                            <div className="text-lg text-yellow-400 mb-4 text-center">
                                Aura Points: {auraPoints}
                            </div>

                            <div className="text-lg text-gray-300 mb-4 text-center">{bio}</div> {/* Bio */}
                        </div>
                    </>
                )}
            </main>
        </div>
    );
};

export default Profile;
