import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Landing from './pages/landing/Landing';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import TermsOfService from './pages/landing/TermsOfService';
import PrivacyPolicy from './pages/landing/PrivacyPolicy';
import ContactUs from './pages/landing/ContactUs';
import PrivateRoute from './components/auth/PrivateRoute';
import Home from './components/home/Home';
import EditProfile from './pages/profile/EditProfile';
import Profile from './pages/profile/Profile';
import Room from './pages/chatrooms/Room';
import ChatRooms from './pages/chatrooms/Chatrooms';
import Marketplace from './pages/marketplace/Marketplace';

import './App.css';

const NotFound = () => (
  <div className="text-center text-white mt-20">
    <h1 className="text-4xl">404</h1>
    <p className="text-lg mt-2">Page Not Found</p>
  </div>
);

const App = () => (
  <AuthProvider>
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* Protected Routes */}
        <Route path="/home" element={<PrivateRoute element={<Home />} />} />
        <Route path="/edit-profile" element={<PrivateRoute element={<EditProfile />} />} />
        <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
        <Route path="/room" element={<PrivateRoute element={<Room />} />} />
        <Route path="/chatrooms" element={<PrivateRoute element={<ChatRooms />} />} />
        <Route path="/marketplace" element={<PrivateRoute element={<Marketplace />} />} />

        {/* Catch-all Route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>

    </Router>
  </AuthProvider>
);

export default App;
