// Header.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';

const Header = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="bg-gray-900 w-full p-4 shadow-lg">
            <div className="max-w-5xl mx-auto flex justify-between items-center">
                <h1 
                    onClick={() => navigate('/home')}
                    className="cursor-pointer text-xl font-bold text-yellow-400"
                >
                    Unsaid
                </h1>

                {/* Desktop Navigation */}
                <nav className="hidden lg:flex gap-6">
                    <button
                        onClick={() => navigate('/marketplace')}
                        className="text-white hover:text-yellow-400"
                    >
                        Marketplace
                    </button>
                    <button
                        onClick={() => navigate('/chatrooms')}
                        className="text-white hover:text-yellow-400"
                    >
                        Chatrooms
                    </button>
                    <button
                        onClick={() => navigate('/profile')}
                        className="text-white hover:text-yellow-400"
                    >
                        Profile
                    </button>
                </nav>

                {/* Mobile Navigation */}
                <div className="lg:hidden">
                    <Menu>
                        <Menu.Button 
                            className="text-white hover:text-yellow-400"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            ☰
                        </Menu.Button>
                        {isOpen && (
                            <Menu.Items className="absolute right-0 mt-2 w-48 bg-gray-800 p-2 rounded-md shadow-lg">
                                <Menu.Item>
                                    <button
                                        onClick={() => {
                                            navigate('/marketplace');
                                            setIsOpen(false);
                                        }}
                                        className="block w-full text-left text-white hover:text-yellow-400"
                                    >
                                        Marketplace
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button
                                        onClick={() => {
                                            navigate('/chatrooms');
                                            setIsOpen(false);
                                        }}
                                        className="block w-full text-left text-white hover:text-yellow-400"
                                    >
                                        Chatrooms
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button
                                        onClick={() => {
                                            navigate('/profile');
                                            setIsOpen(false);
                                        }}
                                        className="block w-full text-left text-white hover:text-yellow-400"
                                    >
                                        Profile
                                    </button>
                                </Menu.Item>
                            </Menu.Items>
                        )}
                    </Menu>
                </div>
            </div>
        </header>
    );
};

export default Header;
